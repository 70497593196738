/*
 * Debounce to limit a number of times a function is called
 * @param func function to debounce
 * @param delay time to delay the function call
 * @param immediate boolean to call the function immediately
 */
function debounce (func, delay, immediate) {
  var timeout

  return function executedFunction () {
    var context = this
    var args = arguments

    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, delay)

    if (callNow) func.apply(context, args)
  }
}

export { debounce }
